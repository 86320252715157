import { render, staticRenderFns } from "./InterRegisterForm.vue?vue&type=template&id=4cebb1ed&scoped=true"
import script from "./InterRegisterForm.vue?vue&type=script&lang=js"
export * from "./InterRegisterForm.vue?vue&type=script&lang=js"
import style0 from "./InterRegisterForm.vue?vue&type=style&index=0&id=4cebb1ed&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cebb1ed",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneNumber: require('/var/app/components/PhoneNumber.vue').default})
