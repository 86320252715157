
export default {
    name: 'Register',
    middleware: 'auth',
    data: () => ({
        registerModal: false,
    }),
    head() {
        return {
            title: `Join us - ${
                this.$config.DOMAIN?.includes('www')
                    ? this.$config.DOMAIN?.split('.')[1]
                    : this.$config.DOMAIN?.split('.')[0]
            }`,
            meta: [
                {
                    hid: 'og:title',
                    name: 'og:title',
                    content: `Join us - ${
                        this.$config.DOMAIN?.includes('www')
                            ? this.$config.DOMAIN?.split('.')[1]
                            : this.$config.DOMAIN?.split('.')[0]
                    }`, // This can be overridden by page-level meta tags.
                },
            ],
        }
    },
    mounted() {
        this.registerModal = true
    },
    methods: {
        doClickOutsize() {
            this.$router.push('/')
        },
    },
}
