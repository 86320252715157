import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=18abd0bb"
import script from "./RegisterForm.vue?vue&type=script&lang=js"
export * from "./RegisterForm.vue?vue&type=script&lang=js"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=18abd0bb&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PhoneNumber: require('/var/app/components/PhoneNumber.vue').default})
